import { Element, Transforms, Range, Node, Editor } from 'slate';
import { getAllLinks } from './link';
const isBookmark = (node) => Element.isElement(node) && node.type === 'bookmark';

const withBookmarks =
  (options = {}) =>
  (editor) => {
    const { isInline, renderElement, normalizeNode } = editor;
    const { deleteBookmark, documentId } = options;
    editor.isInline = (element) => isBookmark(element) || isInline(element);

    editor.renderElement = (props) => {
      if (isBookmark(props.element)) {
        return <BookmarkElement {...props} />;
      } else {
        return renderElement(props);
      }
    };
    editor.normalizeNode = (entry) => {
      const [node, path] = entry;

      if (isBookmark(node)) {
        if (Node.string(node) === '') {
          const links = getAllLinks(editor);
          const matchedLinks = links.filter((link) => link.bookmarkId === node.id);
          matchedLinks.forEach((link) => {
            Transforms.unwrapNodes(editor, { at: link.path });
          });
          deleteBookmark({ id: node.id, documentSuperId: documentId });
          Transforms.unwrapNodes(editor, { at: path });
          return;
        }
      }

      normalizeNode(entry);
    };
    return editor;
  };

export const BookmarkElement = ({ attributes, children, element }) => {
  return (
    <span {...attributes} id={element.id} className="xrp-bookmark">
      {children}
    </span>
  );
};

export const insertBookmark = async (editor, bookmarkId, label, documentSuperId, sectionId) => {
  if (!editor.selection || Range.isCollapsed(editor.selection)) return;
  const bookmark = {
    type: 'bookmark',
    id: bookmarkId,
    label: label,
    children: [],
  };

  Transforms.wrapNodes(editor, bookmark, { split: true });
};

export const removeBookmark = (id, editor) => {
  const [nodeEntry] = Editor.nodes(editor, {
    match: (node) => node.type === 'bookmark' && node.id === id,
    at: [],
  });
  const links = getAllLinks(editor);
  const matchedLinks = links.filter((link) => link.bookmarkId === id);
  matchedLinks.forEach((link) => {
    Transforms.unwrapNodes(editor, { at: link.path });
  });
  Transforms.unwrapNodes(editor, { at: nodeEntry[1] });
};

export const getBookmarkById = (editor, bookmarkId) => {
  const bookmarks = [];

  for (const [node, path] of Node.nodes(editor, { reverse: false })) {
    if (node.type === 'bookmark') {
      bookmarks.push({
        id: node.id,
        path,
        content: Node.string(node),
      });
    }
  }
  return { all: bookmarks, found: bookmarks.find((bm) => bm.id === bookmarkId) };
};

export default withBookmarks;
