import React, { useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { Loader, Spinner, Modal, Dropdown } from 'app/components';
import { useEditorSelector, useEditorDispatch } from 'app/state/contexts/EditorContext';
import { DataImage } from 'app/components';
import { Clickable } from 'app/components/Clickable';
import { useImage, useImages } from 'app/api/documents/image-queries';
import { useStyle, useSaveStyle } from 'app/api/style-queries';
import { setDocument as setReduxDocument } from 'app/state/redux/documentSlice';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { useMovePageToSection, useGetSectionPages, useClonePage } from 'app/api/documents/page-queries';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import Tippy from '@tippyjs/react';
import { cloneDeepPage } from 'app/slate/components/page/utils';
import { dispatchMessage, EventType } from 'app/useToasts';

const BackgroundPicker = ({ name, onChange, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const handleClose = () => {
    setIsOpen(false);
  };
  const { content } = useEditorSelector((editor) => editor.pDocument);
  const imageSuperId = defaultValue
    ?.match(/\((.*)\)/)
    .pop()
    .split('--xrp-resource-')[1];
  const imageRevisionId = content.images.filter((image) => image.superId === imageSuperId)[0]?.revisionId;
  const { data: fetchedImage } = useImage(imageRevisionId);

  const handleSelect = (image) => {
    const newValue = `var(--xrp-resource-${image.superId})`;
    setValue(newValue);
    onChange({ [name]: newValue });
    setIsOpen(false);
  };

  const handleDelete = () => {
    setValue();
    onChange({ [name]: undefined });
  };

  const handleClick = () => {
    setIsOpen(true);
  };
  return (
    <div>
      <div className="text-blue-700 fw-bolder mb-1">{i18n('doc-editor.sidebar.page-settings.xrpBackroundImage')}</div>
      <div className="d-flex align-items-center">
        <Clickable className="border flex-grow-1 rounded d-flex align-items-center p-1" onClick={handleClick}>
          <img
            alt=""
            src={fetchedImage ? `data:${fetchedImage?.fileType};base64,${fetchedImage?.fileContent}` : ''}
            style={{ width: '57px', objectFit: 'cover' }}
            className="bg-blue-300 aspect-1x1 me-2"
          />
          <div>{value || i18n('doc-editor.sidebar.page-settings.no-image')}</div>
        </Clickable>
        {value ? (
          <button type="button" className={`bg-transparent ms-2 border-0`} onClick={handleDelete}>
            <i className={`fa-regular fa-trash`} />
          </button>
        ) : null}
      </div>
      <BackgroundSelectorModal open={isOpen} onClose={handleClose} onSelect={handleSelect} />
    </div>
  );
};

const SettingsSlider = ({ name, onChange, defaultValue, range, step }) => {
  const [value, setValue] = useState(defaultValue ?? 0);
  const [saveSettings] = useDelayedSave(onChange, 200);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    saveSettings({ [name]: newValue });
  };

  return (
    <div className="mt-4">
      <div className="text-blue-700 fw-bolder">{i18n(`doc-editor.sidebar.page-settings.${name}`)}</div>
      <input
        type="range"
        min={range.at(0)}
        max={range.at(-1)}
        step={step}
        list={`markers-${name}`}
        className="w-100"
        onChange={handleChange}
        name={name}
        value={value}
      />
      <datalist id={`markers-${name}`}>
        {range.map((v) => (
          <option key={v} value={v}></option>
        ))}
      </datalist>
    </div>
  );
};

const MovePage = ({ selectedPage, activeSectionId, onMovePage, documentRevisionId, sections }) => {
  const sectionIds = sections.map((section) => section.revisionId);
  const { data, isLoading } = useGetSectionPages(documentRevisionId, sectionIds);
  const [copyPage, setCopyPage] = useState(false);
  const formattedPages = data?.flat().map((item) => ({
    sectionId: item.sectionId,
    title: item.pageRevisionId ? `Sida ${item.idx}` : i18n('doc-editor.sidebar.page-settings.move-page.move-to-end'),
    pageId: item.pageRevisionId,
    sectionName: item.name,
    pageIndex: item.internalIdx,
    value: item.id,
  }));

  const fromPage = formattedPages?.filter((page) => page.pageId !== null)[selectedPage - 1];
  const handleChange = (pageId) => {
    const targetPage = formattedPages.find((page) => page.value === pageId);

    onMovePage({ fromPage: fromPage, targetPage, copyPage });
  };
  return (
    <div className="mt-4 ">
      <div>
        <div className="flex flex-row items-center" style={{ marginBottom: '0.5rem' }}>
          <p className="text-blue-700 fw-bolder" style={{ marginBottom: 0 }}>
            {i18n('doc-editor.sidebar.page-settings.move-page.title')}
          </p>
          <Tippy content={<div className="bg-gray-100 p-2 rounded">{i18n('doc-editor.sidebar.page-settings.move-page.desc')}</div>}>
            <i className="fa-light fa-circle-question text-blue-700" style={{ display: 'flex', alignItems: 'center', marginLeft: '0.75rem' }}></i>
          </Tippy>
        </div>
        <div className="flex flex-row items-center mb-2">
          <p className="m-0 me-2">{i18n('doc-editor.sidebar.page-settings.move-page.copy')}</p>
          <input type="checkbox" role="switch" value={copyPage} onChange={(e) => setCopyPage(!copyPage)} />
        </div>
      </div>
      {!isLoading && (
        <Dropdown
          sm
          items={formattedPages.filter((page) => (!copyPage ? page.sectionId !== activeSectionId : true))}
          value={''}
          onChange={handleChange}
          disabled={isLoading}
          groupbyKey={'sectionName'}
        />
      )}
    </div>
  );
};

const BackgroundSelectorModal = ({ onSelect, open, onClose }) => {
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const { data: images } = useImages(pDocument.content.images);

  const handleSelect = (image) => {
    onSelect(image);
  };
  return (
    <Modal show={open} onClose={onClose} size="lg">
      {!images ? (
        <Loader />
      ) : (
        <div>
          {images.length === 0 && <h1 className="text-center">{i18n('imageimport.no-images')}</h1>}
          <div className="row">
            {images.map((image) => (
              <div key={image.superId} className="col-3 text-center">
                <Clickable onClick={() => handleSelect(image)}>
                  <div className="border bg-gray-100 rounded overflow-hidden" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <DataImage {...image} style={{ height: '100%' }} />
                    <div className="border-top p-2">{image.filename}</div>
                  </div>
                </Clickable>
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export const PageVariables = ({ selectedPage, setSelectedPage }) => {
  const editor = useSlate();
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const section = useEditorSelector((editor) => editor.section);
  const styleId = pDocument.content.design.find((style) => style.pageId === activeSection.uuid)?.styleId;
  const { data } = useStyle({ organization: pDocument.organization, documentSuperId: pDocument.superId, styleId });
  const [localStyle, setLocalStyle] = useState(null);
  const savedStyle = data?.content;
  const { mutate: saveStyle } = useSaveStyle({
    organization: pDocument.organization,
    documentSuperId: pDocument.superId,
    pageId: activeSection.uuid,
    templateSuperId: activeSection.templateSuperId,
  });
  const { mutate: movePage } = useMovePageToSection();
  const { mutate: clonePage } = useClonePage({ documentSuperId: pDocument.superId, pageId: activeSection.uuid });
  const editorDispatch = useEditorDispatch();
  const style = localStyle ?? savedStyle;

  const onMovePage = (data) => {
    if (data.fromPage.sectionId === data.targetPage.sectionId && data.copyPage) {
      const pageContent = section.content[data.fromPage.pageIndex];
      const { clonedPage, idMappings } = cloneDeepPage(pageContent);
      clonePage({ pageId: clonedPage.uuid, fromPage: data.fromPage, targetPage: data.targetPage, idMappings });

      Transforms.insertNodes(editor, clonedPage, { at: [data.targetPage.pageIndex] });
      setSelectedPage(null);
      return;
    } else {
      if (section.content.length === 1 && !data.copyPage) {
        dispatchMessage(EventType.ADD_MESSAGE, {
          type: 'error',
          title: i18n('doc-editor.sidebar.page-settings.move-page.error-title'),
          text: i18n('doc-editor.sidebar.page-settings.move-page.error-desc'),
        });
        return;
      }
      movePage({
        documentId: pDocument.superId,
        fromPage: data.fromPage,
        targetPage: data.targetPage,
        copyPage: data.copyPage,
      });
      if (!data.copyPage) {
        Transforms.removeNodes(editor, { at: [data.fromPage.pageIndex], match: (node) => node.type === 'page' });
      }
    }

    setSelectedPage(null);
  };

  async function handleChange(newProperty) {
    const newStyle = style ? Object.assign({}, style) : {};
    if (Object.values(newProperty)[0] === undefined) {
      delete newStyle[Object.keys(newProperty)[0]];
    } else {
      Object.assign(newStyle, newProperty);
    }

    setLocalStyle(newStyle);
    saveStyle(newStyle, {
      onSuccess: (doc) => {
        editorDispatch(setReduxDocument(doc.data));
      },
    });
  }

  if (style == null && styleId) {
    return <Spinner />;
  }
  return (
    <div className="preview--container">
      <div className="d-flex px-3 pt-4 justify-content-between align-items-center pb-3 w-100 bg-white sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <button onClick={() => setSelectedPage(null)} className="btn-clear bg-transparent me-3" type="button">
            <i className="fa-regular fa-arrow-left" />
          </button>
          <div className="fw-bold">{i18n('doc-editor.sidebar.page-settings')}</div>
        </div>
        <div className="text-gray-800 fs-7">
          {i18n('doc-editor.sidebar.page-prefix')}
          {selectedPage}
        </div>
      </div>
      <div className="px-3 w-100">
        <BackgroundPicker name="xrpBackgroundImage" onChange={handleChange} defaultValue={style?.['xrpBackgroundImage'] ?? null} />
        <SettingsSlider
          name="xrpLineHeightScalingFactor"
          onChange={handleChange}
          defaultValue={style?.['xrpLineHeightScalingFactor'] ?? 0}
          step={0.0005}
          range={[-1.0, -0.5, 0, 0.5, 1]}
        />
        <SettingsSlider
          name="xrpFontSizeScalingFactor"
          onChange={handleChange}
          defaultValue={style?.['xrpFontSizeScalingFactor'] ?? 0}
          step={0.0005}
          range={[-1.0, -0.5, 0, 0.5, 1]}
        />
        <SettingsSlider
          name="xrpLetterSpacingScalingFactor"
          onChange={handleChange}
          defaultValue={style?.['xrpLetterSpacingScalingFactor'] ?? 0}
          step={0.0005}
          range={[-1.0, -0.5, 0, 0.5, 1]}
        />
        <MovePage
          selectedPage={selectedPage}
          documentRevisionId={pDocument.revisionId}
          sections={pDocument.content.sections}
          activeSectionId={section.revisionId}
          onMovePage={onMovePage}
        />
      </div>
    </div>
  );
};
