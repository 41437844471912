import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
export const calulcateCurrentPage = (pDocument, section, pageId, tableOfContentSection) => {
  const pageIndex = section.content?.findIndex((page) => page.uuid === pageId);
  const physicalPage = tableOfContentSection?.range?.start + pageIndex;

  const offset = calculatePageOffset(pDocument.content?.pageNumbering);

  return { physicalPage, documentPage: physicalPage + offset };
};

export const calculatePageOffset = (pageNumberSettings) => {
  if (!pageNumberSettings) {
    return 0;
  }
  return pageNumberSettings.countFrom - pageNumberSettings.showFrom;
};

export const replaceTypeId = (page, replaceFn) => {
  if (typeof replaceFn !== 'function') {
    console.error('replaceFn must be a function');
    return { clonedPage: page, idMappings: [] };
  }

  const deepclone = cloneDeep(page);
  const idMappings = [];
  deepclone.children = deepclone.children.map((node) => replaceFn(node, idMappings));

  return { clonedPage: deepclone, idMappings };
};

const replaceLinksAndBookmarks = (node, idMappings) => {
  if (node.type === 'link') {
    const oldId = node.id;
    const newId = uuidv4();
    const bookmarkId = node.bookmarkId;

    node.id = `lk-${newId}`;
    idMappings.push({ oldId, newId: `lk-${newId}`, bookmarkId });
  } else if (node.type === 'bookmark') {
    return node.children.map((child) => replaceLinksAndBookmarks(child, idMappings))[0];
  }

  if (node.children) {
    node.children = node.children.map((child) => replaceLinksAndBookmarks(child, idMappings));
  }

  return node;
};

// Main function to clone and return the updated page with the content replaced
export const cloneDeepPage = (pageContent) => {
  const { clonedPage, idMappings } = replaceTypeId(pageContent, replaceLinksAndBookmarks);
  const newPageUUID = uuidv4();
  clonedPage.uuid = newPageUUID;
  return { clonedPage, idMappings };
};

const bookmarksAndLinks = (node, idMappings) => {
  if (node.type === 'link' || node.type === 'bookmark') {
    idMappings.push({ id: node.id, type: node.type });
  }
  if (node.children) {
    node.children = node.children.map((child) => bookmarksAndLinks(child, idMappings));
  }

  return node;
};

export const getBookmarkAndLinks = (pageContent) => {
  const { idMappings } = replaceTypeId(pageContent, bookmarksAndLinks);

  return idMappings;
};
