import { useQuery, useMutation, useQueryClient } from 'react-query';
import { request } from 'app/api/request';

export const useBookmarks = (documentSuperId) => {
  const queryClient = useQueryClient();

  // Fetch bookmarks by documentSuperId
  const { data: bookmarks, isLoading: isBookmarksLoading } = useQuery(
    ['bookmarks', documentSuperId],
    async () => {
      const res = await request.get(`/gaby/bookmarks/${documentSuperId}`);
      return res.data;
    },
    {
      enabled: !!documentSuperId,
      onSuccess: () => {
        if (documentSuperId) {
          queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]);
        }
      },
    }
  );

  // Fetch bookmarks and links by documentId
  const { data: bookmarkAndLinks, isLoading: isBookmarksAndLinksLoading } = useQuery(
    ['bookmarksAndLinks', documentSuperId],
    async () => {
      const res = await request.get(`/gaby/bookmarks/links/${documentSuperId}`);
      return res.data;
    },
    {
      enabled: !!documentSuperId, // only run if documentId exists
      keepPreviousData: true,
    }
  );

  // Mutation for creating a link
  const createLink = useMutation(
    async ({ linkId, documentSuperId, sectionId, label, bookmarkId }) => {
      await request.post(`/gaby/bookmarks/link/`, { linkId, documentSuperId, sectionId, label, bookmarkId });
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]); // Refetch data after success
      },
    }
  );

  // Mutation for deleting a link
  const deleteLink = useMutation(
    async ({ id, documentSuperId }) => {
      await request.del(`/gaby/bookmarks/link/${id}`);
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]); // Refetch data after success
        queryClient.invalidateQueries(['bookmarks', documentSuperId]);
      },
    }
  );

  // Mutation for creating a bookmark
  const createBookmark = useMutation(
    async ({ bookmarkId, label, documentSuperId, sectionId }) => {
      await request.post(`/gaby/bookmarks/`, { bookmarkId, label, documentSuperId, sectionId });
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]); // Refetch data after success
        queryClient.invalidateQueries(['bookmarks', documentSuperId]);
      },
    }
  );

  // Mutation for deleting a bookmark
  const deleteBookmark = useMutation(
    async ({ id, documentSuperId }) => {
      await request.del(`/gaby/bookmarks/${id}`);
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]); // Refetch data after success
        queryClient.invalidateQueries(['bookmarks', documentSuperId]);
      },
    }
  );

  const updateLink = useMutation(
    async ({ id, bookmarkId }) => {
      await request.put(`/gaby/bookmarks/link/${id}`, { bookmarkId });
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        queryClient.invalidateQueries(['bookmarksAndLinks', documentSuperId]);
      },
    }
  );

  return {
    // Queries
    bookmarks,
    isBookmarksLoading,
    bookmarkAndLinks,
    isBookmarksAndLinksLoading,

    // Mutations
    createBookmark: createBookmark.mutate,
    deleteBookmark: deleteBookmark.mutate,
    createLink: createLink.mutate,
    deleteLink: deleteLink.mutate,
    updateLink: updateLink.mutate,
  };
};
