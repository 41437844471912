import { useQuery, useQueryClient, useMutation } from 'react-query';
import { request } from 'app/api/request';
import { errorHandler } from 'app/utils/messages';
import ServerErrorHandler from 'app/ErrorHandler';

export const queryKeys = {
  page: 'PAGE',
  pages: 'PAGES',
  css: 'CSS',
  style: 'STYLE',
};

export const useGetSectionPages = (documentRevisionId, sectionRevisionIds) => {
  return useQuery(
    [{ documentRevisionId, sectionRevisionIds }, queryKeys.pages],
    async () => {
      return request
        .post(`/gaby/documents/${documentRevisionId}/pages`, {
          sectionIds: sectionRevisionIds,
        })
        .then((res) => res.data)
        .catch((e) => {
          errorHandler(e);
          throw new Error('Something went wrong when fetching pages: ', e);
        });
    },
    { enabled: !!documentRevisionId, keepPreviousData: true }
  );
};
export const useMovePageToSection = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ documentId, fromPage, targetPage }) =>
      await request.post(`/gaby/documents/${documentId}/actions`, {
        action: 'set_page_order',
        data: {
          fromPage,
          targetPage,
        },
      }),
    {
      onSuccess: (_, ctx) => {
        queryClient.invalidateQueries(ctx.documentId);
      },
      onError: ServerErrorHandler,
    }
  );
};

export const useClonePage = ({ documentSuperId }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ pageId, fromPage, targetPage, idMappings }) => {
      const action = {
        action: 'clone_page',
        data: {
          documentSuperId,
          pageId,
          fromPage,
          targetPage,
          idMappings,
        },
      };
      return await request.post(`/gaby/documents/${documentSuperId}/actions`, action);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.css);
        queryClient.invalidateQueries(queryKeys.style);
      },
    }
  );
};

export const useDeletePage = ({ documentSuperId }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ pageId, idMappings }) => {
      const action = {
        action: 'delete_page',
        data: {
          documentSuperId,
          pageId,
          idMappings,
        },
      };
      return await request.post(`/gaby/documents/${documentSuperId}/actions`, action);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.pages);
      },
    }
  );
};
