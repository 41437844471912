import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useParams, useRouteMatch, Switch, Route, NavLink, useHistory } from 'react-router-dom';
import ReferenceDataDetails from './details';
import request from 'app/api/request';
import moment from 'moment';
import { LazyTippy, Loader } from 'app/components';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'app/utils/i18n';
import { FileZone } from 'app/components';
import ReferenceDataDropdownMenu from './reference-data-dropdown-menu';
import { useCreateReferenceData, useReferenceList, useUpdateReferenceData, useGetXliffDownload } from './reference-data-queries';
import { useGetDocument } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts/UserContext';

export default function ReferenceData() {
  const { documentId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const match = useRouteMatch(`${path}/:refId`);
  const refId = match?.params.refId;
  const { selectedOrganization } = useContext(UserContext);
  const { data: pDocument } = useGetDocument(documentId, selectedOrganization);
  const referenceList = useReferenceList(pDocument);
  const { mutate: updateReferenceData } = useUpdateReferenceData();
  const { mutate: getXliffDownload } = useGetXliffDownload(pDocument.superId);
  const [showDetails, setShowDetails] = useState(false);
  const [showNewReferenceForm, setShowNewReferenceForm] = useState(false);
  const [showNewXliffForm, setShowNewXliffForm] = useState(false);
  const [existingReference, setExistingReference] = useState(null);
  useEffect(() => {
    if (referenceList.isLoading || referenceList.isFetching) {
      return;
    }

    if (referenceList.data.length > 0 && !refId) {
      history.push(`${url}/${referenceList.data[0].superId}`);
    } else if (refId && !referenceList.data.find((r) => r.superId === refId)) {
      history.push(`/documents/${documentId}/reference-data`);
    }
  }, [referenceList, refId, history, url, documentId]);

  const xliff = () => {
    const res = getXliffDownload(null, {
      onSuccess: (results) => {
        results.data.forEach(({ data, targetLocale, sourceLocale, name }) => {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'text/xml' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}_translation_${sourceLocale}_to_${targetLocale}.xliff`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      },
    });
    console.log('res', res);
  };

  const onChangeName = (name) => {
    setExistingReference(name);
    setShowNewReferenceForm(true);
  };

  const handleReferenceCreated = useCallback(
    (referenceSuperId) => {
      setShowNewReferenceForm(false);
      history.push(`/documents/${documentId}/reference-data/${referenceSuperId}`);
    },
    [documentId, history]
  );

  const handleNewRefClick = () => {
    setShowNewReferenceForm(true);
    setExistingReference(null);
  };

  const handleNewXliffClick = () => {
    setShowNewXliffForm(!showNewXliffForm);
  };

  const onUploadSuccess = ({ data }) => {
    setShowNewXliffForm(false);
    request.post(`/gaby/reference-data/xliff/${documentId}?xliffId=${data.revisionId}`);
  };

  const onLocaleChange = (item, locale) => {
    const updatedItem = { ...item, revisionId: uuidv4(), locale };
    updateReferenceData({ document: pDocument, referenceData: updatedItem });
  };

  if (referenceList.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="overview--reference--menu">
        <button className="button default bold" onClick={xliff} type="button" disabled={referenceList?.data?.length === 0}>
          {i18n('reference-data.download-xliff')}
        </button>
        <LazyTippy
          interactive={true}
          visible={showNewXliffForm}
          onClickOutside={() => setShowNewXliffForm(false)}
          offset={[20, 10]}
          placement="bottom"
          content={<FileZone type="xliff" documentId={documentId} onSuccess={onUploadSuccess} />}
          disabled={referenceList.data.length === 0}
        >
          <button className="button default bold" onClick={() => handleNewXliffClick()} type="button">
            {i18n('reference-data.upload-xliff')}
          </button>
        </LazyTippy>

        <button className="button default bold" onClick={() => setShowDetails(!showDetails)} type="button" disabled={referenceList.data.length === 0}>
          Filer
        </button>
        <LazyTippy
          interactive={true}
          visible={showNewReferenceForm}
          onClickOutside={() => setShowNewReferenceForm(false)}
          offset={[20, 10]}
          placement="bottom"
          content={<NewReferenceForm pDocument={pDocument} onReferenceCreated={handleReferenceCreated} existingReference={existingReference} />}
        >
          <button className="fab" type="button" onClick={() => handleNewRefClick()}>
            <i className="fa-regular fa-plus font-15" />
          </button>
        </LazyTippy>
      </div>
      <div className="grid--container reference-data--layout">
        <div className="reference-data--list">
          {referenceList.data.map((item, index) => (
            <ReferenceListItem item={item} onChangeName={onChangeName} setShowDetails={setShowDetails} key={index} onLocaleChange={onLocaleChange} />
          ))}
        </div>
        <div className="card info-card">
          <Switch>
            <Route path={`${path}/:refId`}>
              <ReferenceDataDetails showDetails={showDetails} setShowDetails={setShowDetails} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

function ReferenceListItem({ item, onChangeName, setShowDetails, onLocaleChange }) {
  const { documentId } = useParams();
  const { url } = useRouteMatch();
  const linkTo = `${url}/${item.superId}`;
  const active = useRouteMatch(linkTo);
  return (
    <NavLink to={linkTo} key={item.superId}>
      <div key={item.id} className={`d-flex flex-row align-items-center py-4 ${active ? 'bg-white' : ''}`}>
        <i className={`fa-regular fa-grip-lines drag-drop text-blue-500 px-2 ${active ? '' : 'invisible'}`} style={{ fontSize: '1.25rem' }}></i>
        <div className="flex-grow-1 overflow-hidden pe-3">
          <h4 className="two-line-overflow">{item.name ? item.name : i18n('reference-data.item.missing-file')}</h4>
          <p className="date-text m-0">{moment(item.createdAt).format('D MMM. YYYY, HH:mm')}</p>
        </div>
        {active && (
          <ReferenceDataDropdownMenu
            documentId={documentId}
            item={item}
            onChangeName={onChangeName}
            url={url}
            setShowDetails={setShowDetails}
            onLocaleChange={onLocaleChange}
          />
        )}
      </div>
    </NavLink>
  );
}

function NewReferenceForm({ pDocument, onReferenceCreated, existingReference }) {
  const [newReferenceName, setNewReferenceName] = useState(existingReference?.name || '');
  const { mutate: createReferenceData } = useCreateReferenceData();
  const { mutate: updateReferenceData } = useUpdateReferenceData();
  const inputRef = useRef('');
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  const onSubmit = (event) => {
    event.preventDefault();
    if (!newReferenceName) {
      return null;
    }

    const referenceData = {
      superId: uuidv4(),
      documentSuperId: pDocument.superId,
      ...(existingReference || {}),
      name: newReferenceName,
      revisionId: uuidv4(),
    };
    (existingReference ? updateReferenceData : createReferenceData)(
      { document: pDocument, referenceData },
      {
        onSuccess: (res) => {
          setNewReferenceName('');
          onReferenceCreated?.(referenceData.superId);
        },
      }
    );
  };

  return (
    <div className="position-absolute bg-white border border-line rounded px-3 py-r-1" style={{ right: 0, zIndex: 1 }}>
      <form onSubmit={onSubmit}>
        <div className="d-flex flex-row gap-r-1 border border-line rounded border-blue-800 px-3 py-r-1">
          <input
            className="all-unset text-nowrap"
            style={{ width: '250px' }}
            type="text"
            value={newReferenceName}
            ref={inputRef}
            onChange={(event) => setNewReferenceName(event.target.value)}
          />
          <button type="submit" className="all-unset d-block text-nowrap fw-bold fs-5 text-uppercase">
            {existingReference ? i18n('reference-data.dropdown.edit-name') : i18n('reference-data.item.dropdown.create-name')}
          </button>
        </div>
      </form>
    </div>
  );
}
