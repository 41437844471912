import { useSlate } from 'slate-react';
import { Editor, Range } from 'slate';
import { forwardRef, useState } from 'react';
import { insertBookmark } from '../../../slate/extensions/bookmark';
import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useBookmarks } from '../../../utils/hooks/useBookmarks';
import { v4 as uuidv4 } from 'uuid';

export const BookmarkButton = forwardRef(({ icon, css }, ref) => {
  const editor = useSlate();
  const { selection } = editor;
  const hasSelectedText = selection && !Range.isCollapsed(selection);

  const [match] = Editor.nodes(editor, {
    at: editor.selection, // The current selection
    match: (n) => n.type === 'bookmark', // Check if the node type is 'bookmark'
    mode: 'all', // Iterate over all nodes in the selection
  });
  const isBookmarkInSelection = !!match;

  return (
    <button ref={ref} className={`${css}`} disabled={!hasSelectedText || isBookmarkInSelection}>
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});

export const BookmarkContent = forwardRef((_, ref) => {
  const editor = useSlate();
  const [bookmarkLabel, setBookmarkLabel] = useState('');
  const { documentSuperId, superId: sectionId } = useEditorSelector((editor) => editor.section);
  const { createBookmark } = useBookmarks();

  const handleSubmit = async () => {
    if (bookmarkLabel === '') return;
    const bookmarkId = `bm-${uuidv4()}`;

    createBookmark({ bookmarkId, label: bookmarkLabel, documentSuperId, sectionId });
    insertBookmark(editor, bookmarkId, bookmarkLabel, documentSuperId, sectionId);
    setBookmarkLabel('');
  };

  return (
    <div
      ref={ref}
      style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem 1rem 0.5rem 1rem' }}
      className={`bookmarkbar-content bookmark-button-wrapper`}
    >
      <p>{i18n('editor.subtoolbar.bookmark.label')}</p>
      <div className="bookmark-button-inner">
        <input
          type="text"
          value={bookmarkLabel}
          onChange={(e) => setBookmarkLabel(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSubmit();
          }}
        />
        <i className="fa-solid fa-check" onClick={handleSubmit} />
        <i className="fa-solid fa-x" onClick={() => setBookmarkLabel('')} />
      </div>
    </div>
  );
});
