import { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReactEditor, useSlate } from 'slate-react';
import { Range, Editor } from 'slate';
import request from 'app/api/request';
import { EditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { createPortal } from 'react-dom';
import { refHandler } from 'app/utils/helper-functions';
import { usePopper } from 'react-popper';
import { times } from 'ramda';
import { i18n } from 'app/utils/i18n';
import { useSelector } from 'react-redux';
import { StructurePreview } from 'app/views/documents/settings/page-structure/StructurePreview';
import { Modal } from 'app/components';
import Area from './Area';
import { insertTemplate } from 'app/slate/extensions/page';

const PageDivider = ({ index, pageRef, placement, readOnly }) => {
  const editor = useSlate();
  const { sectionId } = useParams();
  const reduxSectionPermissions = useSelector((state) => state.document.sectionPermissions);
  const [popperElement, setPopperElement] = useState(null);
  const [showNewPageModal, setShowNewPageModal] = useState(false);
  const { styles, attributes } = usePopper(pageRef, popperElement, {
    placement,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
        },
      },
    ],
  });

  const isSectionButtonDisabled = () => {
    return reduxSectionPermissions[sectionId] === 'COMMENT';
  };

  if (readOnly) {
    return <Portal />;
  }

  return (
    <Portal>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <div className="page-divider">
            {isSectionButtonDisabled() ? (
              <button disabled className="page-divider--button text-disabled">
                {i18n('doc-editor.page-divider.link.new-page')} <i className="fa-regular fa-file" />
              </button>
            ) : (
              <button className="page-divider--button" onClick={() => setShowNewPageModal(true)}>
                {i18n('doc-editor.page-divider.link.new-page')} <i className="fa-regular fa-file" />
              </button>
            )}
            {<LayoutPicker show={showNewPageModal} editor={editor} index={index} onClose={() => setShowNewPageModal(false)} />}
          </div>
        </div>
      </div>
    </Portal>
  );
};

const emptyExtra = (index) => (
  <div key={index} className="page-wrapper offset-page" contentEditable={false} style={{ userSelect: 'none' }}>
    <div className="page-content"></div>
  </div>
);

export const LayoutPicker = ({ onClose, show, ...rest }) => {
  const reduxTemplates = useSelector((state) => state.document.templates);

  return (
    <Modal titleLabelKey="layout-picker.title" show={show} onClose={onClose} size="xl">
      <div className="d-flex flex-wrap justify-content-start gap-r-2">
        {reduxTemplates.map((template) => (
          <Layout {...rest} onSelect={onClose} template={template} key={template.revisionId} />
        ))}
      </div>
    </Modal>
  );
};

const Layout = ({ editor, index, template, onSelect }) => {
  const handleChoseLayout = () => {
    insertTemplate(editor, template, index);
    onSelect();
  };
  return (
    <div className="mb-3">
      <StructurePreview template={template} onClick={handleChoseLayout} />
    </div>
  );
};

export const SectionName = () => {
  const revisionIdLeft = useQuery().get('revisionL');
  const { documentId, sectionId } = useParams();
  const [sectionL, setSectionL] = useState({});

  useEffect(() => {
    Promise.all([getDocumentRevision(revisionIdLeft, documentId)])
      .then(([documentL]) => {
        return Promise.all([getSectionRevision(documentL, sectionId)]);
      })
      .then(([sectionRevisionL]) => {
        setSectionL(sectionRevisionL);
      });
  }, [revisionIdLeft, documentId, sectionId]);

  return <span className="page-divider-section-name">{sectionL.name}</span>;
};

const Portal = ({ children }) => {
  const { editorContainerRef } = useContext(EditorContext);
  if (editorContainerRef.current == null) return null;
  return createPortal(children, editorContainerRef.current);
};

const getDocumentRevision = (documentRevisionId, documentId) => {
  if (documentRevisionId) {
    return request.get(`/gaby/documents/${documentRevisionId}?compileSass=true`).then((res) => res.data);
  } else {
    return request
      .get(`/gaby/documents?superId=${documentId}&current=true&compileSass=true`)
      .then((res) => res.data)
      .then((documents) => {
        return documents?.[0];
      });
  }
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getSectionRevision = (document, sectionSuperId) => {
  const sectionRevisionId = document.content.sections.find((section) => section.superId === sectionSuperId)?.revisionId;
  return request.get(`/gaby/sections/${sectionRevisionId}`).then((res) => res.data);
};

const Page = ({ attributes, children, element, withButtons, readOnly, extraOffset = 0 }) => {
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const editor = useSlate();
  const locale = useEditorSelector((editor) => editor.locale);
  const [pageRef, setPageRef] = useState(null);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [index] = ReactEditor.findPath(editor, element);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && contentRef.current) {
        const containerStyles = getComputedStyle(containerRef.current);
        const containerHeight = Math.ceil(
          parseFloat(containerRef.current.getBoundingClientRect().height) - parseFloat(containerStyles.paddingTop) - parseFloat(containerStyles.paddingBottom)
        );

        const currentAreaContent = containerRef.current.querySelector('.area-content');
        if (currentAreaContent) {
          const isOverflowing = currentAreaContent.scrollHeight > containerHeight;

          const layoutLeaves = currentAreaContent.querySelectorAll('.layout-leaf');
          layoutLeaves.forEach((leaf) => {
            leaf.classList.remove('page-overflow');
          });
          const paragraphs = currentAreaContent.querySelectorAll('p');
          paragraphs.forEach((para) => {
            para.classList.remove('text-overflow');
          });

          if (isOverflowing) {
            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
              const [paragraph] = Editor.nodes(editor, {
                match: (n) => Editor.isBlock(editor, n) && n.type === 'paragraph',
                at: selection,
              });

              if (paragraph) {
                const domNode = ReactEditor.toDOMNode(editor, paragraph[0]);

                const layoutLeaf = domNode.closest('.layout-leaf');
                if (layoutLeaf) {
                  layoutLeaf.classList.add('page-overflow');

                  domNode.classList.add('text-overflow');
                }
              }
            }
          }
        }
      }
    };
    checkOverflow();

    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(contentRef.current);

    return () => resizeObserver.disconnect();
  }, [editor]);
  return (
    <>
      {times(emptyExtra, extraOffset)}
      <div {...attributes} ref={refHandler(attributes, setPageRef)} className="page-wrapper">
        {withButtons && !index ? <PageDivider readOnly={readOnly} index={index} pageRef={pageRef} placement="top" /> : null}
        {withButtons ? <PageDivider readOnly={readOnly} index={index + 1} pageRef={pageRef} placement="bottom" /> : null}
        <div
          className={`page-content xrp-template-${element.templateSuperId || ''}${element.class ? ' ' + element.class : ''}${
            locale ? ' locale-' + locale.toLowerCase() : ''
          }`.trim()}
          id={`id-${element.uuid}`}
          ref={containerRef}
        >
          <div contentEditable={false} className="area-header">
            <Area pageId={element.uuid} areaConfig={pDocument.content?.header} />
          </div>
          <div className="area-content" ref={contentRef}>
            {children}
          </div>
          <div contentEditable={false} className="area-footer">
            <Area pageId={element.uuid} areaConfig={pDocument.content?.footer} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
